// import React, { useState } from "react";
// import { Card, Input, Button, Typography } from "@material-tailwind/react";
// import { Link } from "react-router-dom";
// import { LoadScript } from "@react-google-maps/api";
// import { Autocomplete } from "@react-google-maps/api";
// import axios from "axios";

// export default function Form(props) {
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     pickupLocation: "",
//     stops: [],
//     dropoffLocation: "",
//     phoneNumber: "",
//     fleet: "",
//     date: "",
//     time: "",
//     paymentMethod: "",
//   });
//   const [numStops, setNumStops] = useState(0);
//   const [message, setMessage] = useState("");

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleStopChange = (index, value) => {
//     const newStops = [...formData.stops];
//     newStops[index] = value;
//     setFormData({ ...formData, stops: newStops });
//   };

//   const handleNumStopsChange = (e) => {
//     const value = parseInt(e.target.value, 10);
//     setNumStops(value);
//     setFormData({ ...formData, stops: Array(value).fill("") });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const stopsString = formData.stops.join(", "); // Combine stops into one string
//       const emailData = {
//         to: "receiver email",
//         subject: "Book Now",
//         text: `
//           Name: ${formData.name}
//           Email: ${formData.email}
//           Pickup Location: ${formData.pickupLocation}
//           Stops: ${stopsString}
//           Drop-off Location: ${formData.dropoffLocation}
//           Phone Number: ${formData.phoneNumber}
//           Fleet: ${formData.fleet}
//           Date: ${formData.date}
//           Time: ${formData.time}
//           Payment Method: ${formData.paymentMethod}
//         `,
//       };

//       const response = await axios.post(
//         "https://lutonbackend.onrender.com/api/send-email",
//         emailData
//       );
//       setMessage("Your message has been sent.");
//       // Reset form fields
//       setFormData({
//         name: "",
//         email: "",
//         pickupLocation: "",
//         stops: [],
//         dropoffLocation: "",
//         phoneNumber: "",
//         fleet: "",
//         date: "",
//         time: "",
//         paymentMethod: "",
//       });
//     } catch (error) {
//       setMessage("Error sending email. Please try again later.");
//       console.error(error);
//     }
//   };

//   const onLoad = (autocomplete) => {
//     setAutocomplete(autocomplete);
//   };

//   const onPlaceChanged = () => {
//     if (autocomplete !== null) {
//       console.log(autocomplete.getPlace());
//     } else {
//       console.log("Autocomplete is not loaded yet!");
//     }
//   };

//   return (
//     <div className="container flex justify-center lightBg">
//       <LoadScript googleMapsApiKey="" libraries={["places"]}>
//         <Card color="transparent" shadow={false} className="mt-36 mb-36">
//           <Typography variant="h2" color="blue-gray">
//             {props.heading}
//           </Typography>

//           <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
//             <div className="mb-1 flex flex-col gap-6">
//               {!props.quotation && (
//                 <>
//                   <Typography variant="h6" color="blue-gray" className="-mb-3">
//                     Name
//                   </Typography>
//                   <Input
//                     size="lg"
//                     placeholder="Enter Full Name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     name="name"
//                   />
//                   <Typography variant="h6" color="blue-gray" className="-mb-3">
//                     Email
//                   </Typography>
//                   <Input
//                     size="lg"
//                     placeholder="Enter Email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     name="email"
//                   />
//                 </>
//               )}

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Pickup Location
//               </Typography>
//               <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
//                 <Input
//                   size="lg"
//                   placeholder="Enter Pickup Location"
//                   value={formData.pickupLocation}
//                   onChange={handleChange}
//                   name="pickupLocation"
//                 />
//               </Autocomplete>

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Select number of stops in between journey
//               </Typography>
//               <select
//                 className="border p-2"
//                 value={numStops}
//                 onChange={handleNumStopsChange}
//               >
//                 <option value="0">0 Stops</option>
//                 <option value="1">1 Stop</option>
//                 <option value="2">2 Stops</option>
//                 <option value="3">3 Stop</option>
//                 <option value="4">4 Stops</option>
//                 <option value="5">5 Stop</option>
//                 <option value="6">6 Stops</option>
//               </select>

//               {Array.from({ length: numStops }).map((_, index) => (
//                 <Input
//                   key={index}
//                   size="lg"
//                   placeholder={`Stop ${index + 1}`}
//                   value={formData.stops[index] || ""}
//                   onChange={(e) => handleStopChange(index, e.target.value)}
//                 />
//               ))}

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Drop-off Location
//               </Typography>
//               <Input
//                 size="lg"
//                 placeholder="Enter Drop-off Location"
//                 value={formData.dropoffLocation}
//                 onChange={handleChange}
//                 name="dropoffLocation"
//               />

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Phone Number
//               </Typography>
//               <Input
//                 size="lg"
//                 placeholder="+44 1234 567890"
//                 value={formData.phoneNumber}
//                 onChange={handleChange}
//                 name="phoneNumber"
//               />
//               <Typography
//                 variant="small"
//                 color="gray"
//                 className="mt-2 flex items-center gap-1 font-normal"
//               >
//                 Enter a phone number starting with country code
//               </Typography>

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Choose Fleet
//               </Typography>
//               <select
//                 className="border p-2"
//                 value={formData.fleet}
//                 onChange={handleChange}
//                 name="fleet"
//               >
//                 <option value="">Select Fleet</option>
//                 <option value="up to 4 people - 2 large bags">
//                   Up to 4 people - 2 large bags
//                 </option>
//                 <option value="up to 6 people - 3 large bags">
//                   Up to 6 people - 3 large bags
//                 </option>
//                 <option value="up to 8 people - 3 large bags">
//                   Up to 8 people - 3 large bags
//                 </option>
//                 <option value="wheelchair">Wheelchair</option>
//               </select>

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Date
//               </Typography>
//               <Input
//                 size="lg"
//                 placeholder="Select Date"
//                 type="date"
//                 value={formData.date}
//                 onChange={handleChange}
//                 name="date"
//               />

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Time
//               </Typography>
//               <Input
//                 size="lg"
//                 placeholder="Select Time"
//                 type="time"
//                 value={formData.time}
//                 onChange={handleChange}
//                 name="time"
//               />

//               <Typography variant="h6" color="blue-gray" className="-mb-3">
//                 Choose Payment Method
//               </Typography>
//               <select
//                 className="border p-2"
//                 value={formData.paymentMethod}
//                 onChange={handleChange}
//                 name="paymentMethod"
//               >
//                 <option value="">Select Payment Method</option>
//                 <option value="debit card">Debit Card</option>
//                 <option value="others">Others</option>
//               </select>
//             </div>

//             <Button
//               className="mt-6"
//               variant="gradient"
//               color="yellow"
//               ripple={true}
//               fullWidth
//               onClick={handleSubmit}
//             >
//               Submit
//             </Button>

//             <Link to="/">
//               <Button
//                 className="mt-4 border-black"
//                 variant="outlined"
//                 color="black"
//                 fullWidth
//               >
//                 Go Back
//               </Button>
//             </Link>
//           </form>
//           {message && <Typography className="mt-3">{message}</Typography>}
//         </Card>
//       </LoadScript>
//     </div>
//   );
// }


import React, { useState } from "react";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { Autocomplete } from "@react-google-maps/api";
import axios from "axios";

export default function Form(props) {
  const [autocomplete, setAutocomplete] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    pickupLocation: "",
    stops: [],
    dropoffLocation: "",
    phoneNumber: "",
    fleet: "",
    date: "",
    time: "",
    paymentMethod: "",
  });
  const [numStops, setNumStops] = useState(0);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStopChange = (index, value) => {
    const newStops = [...formData.stops];
    newStops[index] = value;
    setFormData({ ...formData, stops: newStops });
  };

  const handleNumStopsChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setNumStops(value);
    setFormData({ ...formData, stops: Array(value).fill("") });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const stopsString = formData.stops.join(", "); // Combine stops into one string
      const emailData = {
        to: "receiver email",
        subject: "Book Now",
        text: `
          Name: ${formData.name}
          Email: ${formData.email}
          Pickup Location: ${formData.pickupLocation}
          Stops: ${stopsString}
          Drop-off Location: ${formData.dropoffLocation}
          Phone Number: ${formData.phoneNumber}
          Fleet: ${formData.fleet}
          Date: ${formData.date}
          Time: ${formData.time}
          Payment Method: ${formData.paymentMethod}
        `,
      };

      const response = await axios.post(
        "https://lutonbackend.onrender.com/api/send-email",
        emailData
      );

      if (response.status === 200) {
        setMessage("Email sent successfully!"); // Show success message
      } else {
        setMessage("Error sending email. Please try again later.");
      }

      // Reset form fields
      setFormData({
        name: "",
        email: "",
        pickupLocation: "",
        stops: [],
        dropoffLocation: "",
        phoneNumber: "",
        fleet: "",
        date: "",
        time: "",
        paymentMethod: "",
      });
    } catch (error) {
      setMessage("Error sending email. Please try again later.");
      console.error(error);
    }
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      console.log(autocomplete.getPlace());
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <div className="container flex justify-center lightBg">
      <LoadScript googleMapsApiKey="" libraries={["places"]}>
        <Card color="transparent" shadow={false} className="mt-36 mb-36">
          <Typography variant="h2" color="blue-gray">
            {props.heading}
          </Typography>

          <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
            <div className="mb-1 flex flex-col gap-6">
              {!props.quotation && (
                <>
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    Name
                  </Typography>
                  <Input
                    size="lg"
                    placeholder="Enter Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    name="name"
                  />
                  <Typography variant="h6" color="blue-gray" className="-mb-3">
                    Email
                  </Typography>
                  <Input
                    size="lg"
                    placeholder="Enter Email"
                    value={formData.email}
                    onChange={handleChange}
                    name="email"
                  />
                </>
              )}

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Pickup Location
              </Typography>
              <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                <Input
                  size="lg"
                  placeholder="Enter Pickup Location"
                  value={formData.pickupLocation}
                  onChange={handleChange}
                  name="pickupLocation"
                />
              </Autocomplete>

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Select number of stops in between journey
              </Typography>
              <select
                className="border p-2"
                value={numStops}
                onChange={handleNumStopsChange}
              >
                <option value="0">0 Stops</option>
                <option value="1">1 Stop</option>
                <option value="2">2 Stops</option>
                <option value="3">3 Stops</option>
                <option value="4">4 Stops</option>
                <option value="5">5 Stops</option>
                <option value="6">6 Stops</option>
              </select>

              {Array.from({ length: numStops }).map((_, index) => (
                <Input
                  key={index}
                  size="lg"
                  placeholder={`Stop ${index + 1}`}
                  value={formData.stops[index] || ""}
                  onChange={(e) => handleStopChange(index, e.target.value)}
                />
              ))}

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Drop-off Location
              </Typography>
              <Input
                size="lg"
                placeholder="Enter Drop-off Location"
                value={formData.dropoffLocation}
                onChange={handleChange}
                name="dropoffLocation"
              />

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Phone Number
              </Typography>
              <Input
                size="lg"
                placeholder="+44 1234 567890"
                value={formData.phoneNumber}
                onChange={handleChange}
                name="phoneNumber"
              />
              <Typography
                variant="small"
                color="gray"
                className="mt-2 flex items-center gap-1 font-normal"
              >
                Enter a phone number starting with country code
              </Typography>

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Choose Fleet
              </Typography>
              <select
                className="border p-2"
                value={formData.fleet}
                onChange={handleChange}
                name="fleet"
              >
                <option value="">Select Fleet</option>
                <option value="up to 4 people - 2 large bags">
                  Up to 4 people - 2 large bags
                </option>
                <option value="up to 6 people - 3 large bags">
                  Up to 6 people - 3 large bags
                </option>
                <option value="up to 8 people - 3 large bags">
                  Up to 8 people - 3 large bags
                </option>
                <option value="wheelchair">Wheelchair</option>
              </select>

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Date
              </Typography>
              <Input
                size="lg"
                placeholder="Select Date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                name="date"
              />

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Time
              </Typography>
              <Input
                size="lg"
                placeholder="Select Time"
                type="time"
                value={formData.time}
                onChange={handleChange}
                name="time"
              />

              <Typography variant="h6" color="blue-gray" className="-mb-3">
                Choose Payment Method
              </Typography>
              <select
                className="border p-2"
                value={formData.paymentMethod}
                onChange={handleChange}
                name="paymentMethod"
              >
                <option value="">Select Payment Method</option>
                <option value="debit card">Debit Card</option>
                <option value="others">Others</option>
              </select>
            </div>

            <Button
              className="mt-6"
              variant="gradient"
              color="yellow"
              ripple={true}
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </Button>

            <Link to="/">
              <Button
                className="mt-4 border-black"
                variant="outlined"
                color="black"
                fullWidth
              >
                Go Back
              </Button>
            </Link>
          </form>
          {message && (
            <Typography className={`mt-3 ${message.includes("successfully") ? "text-green-500" : "text-red-500"}`}>
              {message}
            </Typography>
          )}
        </Card>
      </LoadScript>
    </div>
  );
}
