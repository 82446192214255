import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from '../../assets/newimg/logo.png';

export default function App() {
  return (
    <FloatingWhatsApp
      phoneNumber="+447375359283"
      accountName="Taxi For You"
      avatar={Logo } // Pass the imported local image as the avatar
      statusMessage="Typically replies within minutes"
      chatMessage="Hello there! 🤝 \nHow can we help?"
    />
  );
}
